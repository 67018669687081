<template>
  <div class="min-h-screen flex flex-col ">
    <body-background-particles />

    <Header />

    <div class="flex-grow flex flex-col min-h-0 py-6 sm:py-8">
      <slot></slot>
    </div>

    <div :class="this.$route.name === 'Homepage' ? 'mb-48' : ''">
      <Footer />
    </div>
    <cookies-banner v-if="!agreedToPrivacy" />
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import CookiesBanner from "../components/CookiesBanner.vue";
import BodyBackgroundParticles from "../components/Ui/BodyBackgroundParticles.vue";
export default {
  name: "LayoutGuest",
  components: {
    Header,
    Footer,
    CookiesBanner,
    BodyBackgroundParticles,
  },
  computed: {
    agreedToPrivacy() {
      return this.$store.state.agreedToPrivacy;
    },
  },
};
</script>

<style scoped></style>
