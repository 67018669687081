<template>
  <div class="absolute inset-0 z-0 hidden lg:block ">
    <vue-particles
      color="#fff"
      :particleOpacity="0.2"
      :particlesNumber="30"
      shapeType="star"
      :particleSize="4"
      linesColor="#fff"
      :linesWidth="1"
      :lineLinked="false"
      :lineOpacity="1"
      :linesDistance="150"
      :moveSpeed="1"
      :hoverEffect="true"
      hoverMode="grab"
      :clickEffect="false"
    >
    </vue-particles>
  </div>
</template>

<script>
export default {
  name: "BodyBackgroundParticles",
};
</script>
