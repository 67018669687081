import i18n from "../i18n";

export const offers = [
  {
    id: 0,
    name: i18n.t("offers.express.name"),
    slug: i18n.t("offers.express.slug"),
    price: 1.0,
  },
  {
    id: 1,
    name: i18n.t("offers.privilege.name"),
    slug: i18n.t("offers.privilege.slug"),
    price: 29.9,
  },
];
