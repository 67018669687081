import Vue from "vue";
import Vuex from "vuex";
import App from "./App.vue";
import i18n from "./i18n";
import { store } from "./store";
import router from "./router";
import VueMeta from "vue-meta";
import VueMask from "v-mask";
import VueGtag from "vue-gtag";

import { InlineSvgPlugin } from "vue-inline-svg";
import VueParticles from "vue-particles";
import { currentDomain } from "./domains";
import "./assets/css/app.css";

// import "./registerServiceWorker";

Vue.config.productionTip = false;
Vue.use(Vuex);
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
});
Vue.use(VueMask);

Vue.use(
  VueGtag,
  {
    config: {
      id: currentDomain().googleAnalyticsId,
    },
    includes: [{ id: currentDomain().googleAdsId }],
  },
  router
);

Vue.use(InlineSvgPlugin);
Vue.use(VueParticles);

Vue.mixin({
  methods: {
    i18nLabel: (value) => {
      if (i18n.locale === "fr") {
        return value + " : ";
      }
      return value + ": ";
    },
  },
});

new Vue({
  i18n,
  store: store,
  beforeCreate() {
    process.env.VUE_APP_USE_LOCAL_STORAGE === "1" &&
      this.$store.commit("initialiseStore", null);

    // update locale
    this.$store.commit("updateLocale", this.$i18n.locale);
  },
  router,
  render: (h) => h(App),
}).$mount("#app");
