import Vue from "vue";
import Vuex from "vuex";
// import i18n from "../i18n";
import { zodiacSigns } from "./zodiacSigns";
import { testimonials } from "./testimonials";
import { offers } from "./offers";
import { services } from "./services";

Vue.use(Vuex);

export const PREFIX = "astro--";

export const store = new Vuex.Store({
  state: {
    isAuthed: false,
    locale: null,
    userData: {
      email: "",
      name: "",
      location: "",
      postalCode: "",
      gender: "",
      zodiacSign: "",
      birthDay: "",
      birthMonth: "",
      birthDecade: "",
      birthYear: "",
      birthTime: "",
    },
    zodiacSigns,
    totalSteps: 6,
    completedStep: 0,
    offerSelected: false,
    readingComplete: false,
    agreedToPrivacy: false,
    testimonials,
    offers,
    services,
  },

  mutations: {
    initialiseStore(state) {
      if (localStorage.getItem(PREFIX + "agreedToPrivacy")) {
        state.agreedToPrivacy = true;
      }
      if (localStorage.getItem(PREFIX + "userData")) {
        state.userData = JSON.parse(localStorage.getItem(PREFIX + "userData"));
      }
      if (localStorage.getItem(PREFIX + "completedStep")) {
        state.completedStep = JSON.parse(
          localStorage.getItem(PREFIX + "completedStep")
        );
      }
      if (localStorage.getItem(PREFIX + "readingComplete")) {
        state.readingComplete = true;
      }
      if (localStorage.getItem(PREFIX + "offerSelected")) {
        state.offerSelected = JSON.parse(
          localStorage.getItem(PREFIX + "offerSelected")
        );
      }
      if (localStorage.getItem(PREFIX + "readingComplete")) {
        state.readingComplete = JSON.parse(
          localStorage.getItem(PREFIX + "readingComplete")
        );
      }
    },

    updateUserData(state, payload) {
      for (const [key, value] of Object.entries(payload)) {
        if (!value) return;
        state.userData[key] = value;

        localStorage.setItem(
          PREFIX + "userData",
          JSON.stringify(state.userData)
        );
      }
    },

    updateCompletedStep(state, payload) {
      state.completedStep = payload;
      localStorage.setItem(PREFIX + "completedStep", payload);
    },

    updateAgreedToPrivacy(state) {
      state.agreedToPrivacy = true;
      localStorage.setItem(PREFIX + "agreedToPrivacy", true);
    },

    updateReadingComplete(state, payload) {
      state.readingComplete = payload;
      localStorage.setItem(PREFIX + "readingComplete", true);
    },

    updateLocale(state, payload) {
      state.locale = payload;
    },

    updateOfferSelected(state, payload) {
      state.offerSelected = payload;
      localStorage.setItem(
        PREFIX + "offerSelected",
        JSON.stringify(state.offerSelected)
      );
    },
  },
});
