<template>
  <div>
    <LayoutGuest>
      <router-view />
    </LayoutGuest>
  </div>
</template>

<script>
import LayoutGuest from "./layouts/LayoutGuest.vue";
export default {
  name: "App",
  components: {
    LayoutGuest,
  },
  metaInfo() {
    return { title: this.$t("site.title") };
  },
};
</script>

<style></style>
