import i18n from "../i18n";

export const services = [
  {
    id: 1,
    title: i18n.t("service.service_1.title"),
    title_analyse: i18n.t("service.service_1.title_analyse"),
    path: i18n.t("service.service_1.path"),
    short_description: i18n.t("service.service_1.short_description"),
    description: i18n.t("service.service_1.description"),
    cta_text: i18n.t("service.service_1.cta_text"),
  },
  {
    id: 2,
    title: i18n.t("service.service_2.title"),
    title_analyse: i18n.t("service.service_2.title_analyse"),
    path: i18n.t("service.service_2.path"),
    short_description: i18n.t("service.service_2.short_description"),
    description: i18n.t("service.service_2.description"),
    cta_text: i18n.t("service.service_2.cta_text"),
  },
  {
    id: 3,
    title: i18n.t("service.service_3.title"),
    title_analyse: i18n.t("service.service_3.title_analyse"),
    path: i18n.t("service.service_3.path"),
    short_description: i18n.t("service.service_3.short_description"),
    description: i18n.t("service.service_3.description"),
    cta_text: i18n.t("service.service_3.cta_text"),
  },
  {
    id: 4,
    title: i18n.t("service.service_4.title"),
    title_analyse: i18n.t("service.service_4.title_analyse"),
    path: i18n.t("service.service_4.path"),
    short_description: i18n.t("service.service_4.short_description"),
    description: i18n.t("service.service_4.description"),
    cta_text: i18n.t("service.service_4.cta_text"),
  },
  {
    id: 5,
    title: i18n.t("service.service_5.title"),
    title_analyse: i18n.t("service.service_5.title_analyse"),
    path: i18n.t("service.service_5.path"),
    short_description: i18n.t("service.service_5.short_description"),
    description: i18n.t("service.service_5.description"),
    cta_text: i18n.t("service.service_5.cta_text"),
  },
  {
    id: 6,
    title: i18n.t("service.service_6.title"),
    title_analyse: i18n.t("service.service_6.title_analyse"),
    path: i18n.t("service.service_6.path"),
    short_description: i18n.t("service.service_6.short_description"),
    description: i18n.t("service.service_6.description"),
    cta_text: i18n.t("service.service_6.cta_text"),
  },
];
