<template>
  <div>
    <button
      class="flex items-center p-2 -mr-3 justify-center lg:hidden hover:bg-red-500 rounded relative z-50"
      @click="setOpen"
    >
      <inline-svg
        src="/img/icon-hamburger.svg"
        class="w-5 h-5 flex-shrink-0 fill-current"
      />
    </button>
    <div
      :class="isOpen ? 'translate-x-0' : 'translate-x-full'"
      class="flex fixed inset-0 transform transition-transform z-40 items-center justify-center bg-red-500 bg-opacity-95 lg:translate-x-0  lg:static lg:block lg:bg-transparent gap-x-4"
    >
      <nav class="flex flex-col lg:flex-row text-center gap-5">
        <router-link
          :to="path.aProposZodiac"
          class="text-xl lg:text-base px-2 block lg:hover:text-red-500"
          v-text="$t('label.about_zodiac_signs')"
        />
        <router-link
          :to="path.pourquoiTheme"
          class="text-xl lg:text-base px-2 block
        lg:hover:text-red-500"
          v-text="$t('label.why_astral_theme')"
        />
        <router-link
          :to="path.analysesAstro"
          class="text-xl lg:text-base px-2 block
        lg:hover:text-red-500"
          v-text="$t('label.why_unique')"
        />
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  name: "headerMenu",
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    setOpen() {
      this.isOpen = !this.isOpen;
    },
  },
  watch: {
    $route() {
      this.isOpen = false;
    },
  },
  computed: {
    path() {
      return {
        aProposZodiac: `/page/${this.$t(
          "router.a_propos_des_signes_du_zodiac.path"
        )}`,
        pourquoiTheme: `/page/${this.$t(
          "router.pourquoi_le_theme_astral.path"
        )}`,
        analysesAstro: `/page/${this.$t(
          "router.analyses_astro_de_personnalites.path"
        )}`,
      };
    },
  },
};
</script>
