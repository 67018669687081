<template>
  <dropdown>
    <template v-slot:dropdownTrigger>
      <button
        class="sm:border sm:border-white rounded transition-colors bg-transparent p-2 sm:px-4 items-center hover:bg-red-500 hover:text-white hover:border-red-500 inline-flex"
      >
        <inline-svg src="/img/icon-my-astro.svg" class="fill-current" />
        <span class="hidden sm:block ml-2" v-text="$t('button.my_astro')" />
      </button>
    </template>
    <template v-slot:dropdownContent>
      <login-form />
    </template>
  </dropdown>
</template>

<script>
import LoginForm from "./LoginForm.vue";
import Dropdown from "./Ui/Dropdown.vue";
export default {
  components: { LoginForm, Dropdown },
  name: "UserMenu",
};
</script>
