<template>
  <div class="py-4 sm:py-8 flex items-center">
    <container>
      <div class="flex justify-between items-center">
        <div
          class=" -ml-3  sm:ml-0 mr-4 items-center flex-shrink-0 flex lg:hidden order-0"
          v-if="showBackButton"
        >
          <back-button :completedStep="completedStep" />
        </div>
        <div class="sm:mr-auto lg:mr-0 order-1">
          <logo />
        </div>
        <div class="order-3 lg:order-2 ml-2">
          <header-menu />
        </div>
        <div class="order-2 lg:order-3 ml-auto lg:ml-0">
          <user-menu />
        </div>
      </div>
    </container>
  </div>
</template>

<script>
import Logo from "./Logo.vue";
import UserMenu from "./UserMenu.vue";
import HeaderMenu from "./HeaderMenu.vue";
import BackButton from "./BackButton.vue";
import Container from "./Ui/Container.vue";
export default {
  name: "Header",
  components: { UserMenu, HeaderMenu, BackButton, Logo, Container },
  computed: {
    completedStep() {
      return this.$store.state.completedStep;
    },
    totalSteps() {
      return this.$store.state.totalSteps;
    },
    showBackButton() {
      return (
        this.$route.meta.currentStep <= 6 && this.$route.meta.currentStep > 1
      );
    },
  },
};
</script>

<style scoped></style>
