<template>
  <div>
    <div
      class="bg-red-500 mb-4 rounded text-white text-sm p-2 text-center"
      v-if="isFakeError"
      v-text="$t('misc.login_error')"
    />
    <validation-observer
      tag="form"
      v-slot="{ handleSubmit }"
      @submit.prevent="onSubmit"
    >
      <form @submit.prevent="handleSubmit(onSubmit)">
        <validation-provider
          :name="$t('label.email_short')"
          rules="required|email"
          v-slot="{ errors }"
        >
          <div class="mb-3">
            <label
              for="loginEmail"
              class="block mb-2 text-sm"
              v-text="i18nLabel($t('label.email'))"
            />
            <input
              type="text"
              id="loginEmail"
              autocomplete="email"
              v-model="email"
              inputmode="text"
              class="form-control form-control--white"
              :class="{ 'is-invalid': errors[0] }"
            />
            <span class="form-error" v-text="errors[0]" />
          </div>
        </validation-provider>
        <validation-provider
          :name="$t('label.password_short')"
          rules="required"
          v-slot="{ errors }"
        >
          <div>
            <label
              for="loginPassword"
              class="block mb-2 text-sm"
              v-text="i18nLabel($t('label.password'))"
            />
            <input
              type="password"
              id="loginPassword"
              autocomplete="password"
              inputmode="numeric"
              v-model="password"
              v-mask="'#### #### #### ####'"
              class="form-control form-control--white"
              :class="{ 'is-invalid': errors[0] }"
            />
          </div>
          <span class="form-error" v-text="errors[0]" />
        </validation-provider>

        <div class="mt-8">
          <button
            type="submit"
            class="btn bg-green-600 hover:bg-green-500 focus:bg-green-600 text-white w-full"
            v-text="$t('button.login')"
          />
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import i18n from "@/i18n.js";
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  localize,
} from "vee-validate";

import { required, email } from "vee-validate/dist/rules";
import fr from "vee-validate/dist/locale/fr.json";
import it from "vee-validate/dist/locale/it.json";

extend("required", {
  ...required,
  message(field) {
    return i18n.t("messages.required", { field });
  },
});
extend("email", {
  ...email,
});

export default {
  name: "LoginForm",
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  created() {
    this.$store.state.locale === "fr" && localize("fr", fr);
    this.$store.state.locale === "it" && localize("it", it);
  },
  data() {
    return {
      email: "",
      password: "",
      isFakeError: false,
    };
  },
  methods: {
    onSubmit() {
      this.isFakeError = true;
    },
  },
};
</script>
