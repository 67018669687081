<template>
  <h1 class="text-xl sm:text-2xl font-semibold">
    <router-link to="/" class="flex items-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="55.999"
        height="56.004"
        viewBox="0 0 55.999 56.004"
        class="flex-shrink-0 text-red-500 mr-3 sm:mr-4 block w-10 sm:w-14 h-auto"
      >
        <g
          id="Group_426"
          data-name="Group 426"
          transform="translate(-1287 -67)"
        >
          <path
            id="Path_546"
            data-name="Path 546"
            d="M683.429,394.286a1,1,0,0,1,0-2,10,10,0,0,0,0-20,1,1,0,0,1,0-2,12,12,0,0,1,0,24Z"
            transform="translate(631.57 -287.286)"
            fill="#ef4444"
          />
          <path
            id="Path_547"
            data-name="Path 547"
            d="M682.43,393.29a1,1,0,0,1,1-1h0a1,1,0,0,1,1,1h0a.994.994,0,0,1-1,.99h0v.01h0A1,1,0,0,1,682.43,393.29Zm-5.23-.74a1.006,1.006,0,0,1-.33-1.38h0a.992.992,0,0,1,1.37-.33h0a1,1,0,0,1,.34,1.37h0a1,1,0,0,1-.86.479h0A1,1,0,0,1,677.2,392.55Zm-4.42-4.72a1.01,1.01,0,0,1,.43-1.351h0a1.009,1.009,0,0,1,1.35.431h0a1,1,0,0,1-.42,1.34h0a1,1,0,0,1-.47.12h0A1.012,1.012,0,0,1,672.78,387.83Zm-.39-5.26a1.011,1.011,0,0,1-.94-1.06h0a1,1,0,0,1,1.07-.93h0a1,1,0,0,1,.93,1.06h0a.99.99,0,0,1-1,.93h-.06Zm1.45-5.77a1,1,0,0,1-.25-1.39h0a1.007,1.007,0,0,1,1.39-.25h0a1,1,0,0,1,.25,1.4h0a1,1,0,0,1-.82.42h0A.987.987,0,0,1,673.84,376.8Zm4.23-4.18a1.012,1.012,0,0,1,.51-1.32h0a1.006,1.006,0,0,1,1.32.51h0a1,1,0,0,1-.51,1.32h0a.968.968,0,0,1-.4.09h0A1,1,0,0,1,678.07,372.62Z"
            transform="translate(631.57 -287.286)"
            fill="#ef4444"
          />
          <path
            id="Path_548"
            data-name="Path 548"
            d="M688.1,400.729a1,1,0,0,1,.66-1.25h0a1.01,1.01,0,0,1,1.25.661h0a1.011,1.011,0,0,1-.66,1.259h0a1,1,0,0,1-.3.04h0A1,1,0,0,1,688.1,400.729Zm5.49-2.359a1,1,0,0,1,.24-1.391h0a.988.988,0,0,1,1.39.241h0a1,1,0,0,1-.23,1.389h0a1.046,1.046,0,0,1-.58.181h0A1,1,0,0,1,693.59,398.37Zm4.49-3.95a1,1,0,0,1-.2-1.4h0a1,1,0,0,1,1.4-.21h0a1.013,1.013,0,0,1,.2,1.41h0a1,1,0,0,1-.8.4h0A1.029,1.029,0,0,1,698.08,394.42Zm3.05-5.15a.994.994,0,0,1-.63-1.26h0a1.007,1.007,0,0,1,1.27-.64h0a1.01,1.01,0,0,1,.629,1.27h0a.993.993,0,0,1-.949.679h0A.891.891,0,0,1,701.13,389.27Zm1.29-5.84a1,1,0,0,1-.99-1.01h0a1,1,0,0,1,1.009-.99h0a1,1,0,0,1,.991,1.01h0a1.01,1.01,0,0,1-1.01.99Zm-.99-1.01Zm-.84-5.59a1,1,0,0,1,.65-1.26h0a.993.993,0,0,1,1.25.65h0a1,1,0,0,1-.65,1.26h0a1.033,1.033,0,0,1-.3.04h0A.986.986,0,0,1,700.59,376.83Zm-2.55-5.06h0a.993.993,0,0,1,.23-1.39h0a.992.992,0,0,1,1.39.22h0a1.015,1.015,0,0,1-.221,1.4h0a1,1,0,0,1-.589.19h0A1.01,1.01,0,0,1,698.04,371.77Zm-3.99-4.02a.991.991,0,0,1-.21-1.39h0a.983.983,0,0,1,1.389-.22h0a1,1,0,0,1,.221,1.4h0a1,1,0,0,1-.81.41h0A1.018,1.018,0,0,1,694.05,367.75Zm-5.03-2.58a1,1,0,0,1-.64-1.26h0a1,1,0,0,1,1.26-.64h0a1.006,1.006,0,0,1,.64,1.26h0a1.006,1.006,0,0,1-.95.69h0A1.109,1.109,0,0,1,689.02,365.17Zm-6.59-1.88a1,1,0,0,1,1-1h0a1,1,0,0,1,1,1h0a1,1,0,0,1-1,1h0A1,1,0,0,1,682.43,363.29Z"
            transform="translate(631.57 -287.286)"
            fill="#ef4444"
          />
          <path
            id="Path_549"
            data-name="Path 549"
            d="M683.429,402.286a20,20,0,0,1,0-40,1,1,0,0,1,0,2,18,18,0,0,0,0,36,1,1,0,0,1,0,2Z"
            transform="translate(631.57 -287.286)"
            fill="#ef4444"
          />
          <path
            id="Path_550"
            data-name="Path 550"
            d="M683.429,410.286a1,1,0,0,1,0-2,26,26,0,0,0,0-52,1,1,0,0,1,0-2,28,28,0,0,1,0,56Z"
            transform="translate(631.57 -287.286)"
            fill="#ef4444"
          />
          <path
            id="Path_551"
            data-name="Path 551"
            d="M682.43,409.29a1,1,0,0,1,1-1h0a1,1,0,0,1,1,1h0a.994.994,0,0,1-1,.99h0v.01h0A1,1,0,0,1,682.43,409.29Zm-5.17.31a1,1,0,0,1-.76-1.19h0a1.013,1.013,0,0,1,1.2-.761h0a.994.994,0,0,1,.75,1.2h0a.994.994,0,0,1-.97.78h0A.833.833,0,0,1,677.26,409.6Zm-5.87-2.031a.991.991,0,0,1-.47-1.329h0a.993.993,0,0,1,1.33-.47h0a.993.993,0,0,1,.47,1.33h0a1,1,0,0,1-.9.57h0A.953.953,0,0,1,671.39,407.569Zm-5.27-3.279a.992.992,0,0,1-.17-1.4h0a1,1,0,0,1,1.4-.17h0a1,1,0,0,1,.17,1.41h0a1,1,0,0,1-.78.38h0A.967.967,0,0,1,666.12,404.29Zm-4.43-4.351h0a1.01,1.01,0,0,1,.15-1.409h0a1,1,0,0,1,1.41.15h0a1.013,1.013,0,0,1-.15,1.41h0a.988.988,0,0,1-.63.22h0A1.018,1.018,0,0,1,661.69,399.939Zm-3.36-5.219h0a1.006,1.006,0,0,1,.46-1.34h0a1.011,1.011,0,0,1,1.34.45h0a1,1,0,0,1-.45,1.34h0a1.064,1.064,0,0,1-.45.11h0A1.018,1.018,0,0,1,658.33,394.72Zm-2.12-5.83a1,1,0,0,1,.74-1.21h0a1,1,0,0,1,1.21.74h0a1,1,0,0,1-.74,1.2h0a.983.983,0,0,1-.24.029h0A1,1,0,0,1,656.21,388.89Zm-.78-6.16h0a.99.99,0,0,1,.99-1.01h0a.994.994,0,0,1,1.01.98h0a.988.988,0,0,1-.98,1.01h-.02A1,1,0,0,1,655.43,382.73Zm1.36-4.99a1,1,0,0,1-.77-1.19h0a.988.988,0,0,1,1.18-.77h0a.988.988,0,0,1,.77,1.18h0a.993.993,0,0,1-.97.8h0A1.491,1.491,0,0,1,656.79,377.74Zm1.66-5.76a1,1,0,0,1-.5-1.33h0a1.006,1.006,0,0,1,1.33-.49h0a1,1,0,0,1,.49,1.32h0a1,1,0,0,1-.91.59h0A.978.978,0,0,1,658.45,371.98Zm2.89-5.25a.994.994,0,0,1-.19-1.4h0a.992.992,0,0,1,1.4-.19h0a1,1,0,0,1,.19,1.4h0a1,1,0,0,1-.8.39h0A.963.963,0,0,1,661.34,366.73Zm3.97-4.49a.987.987,0,0,1,.12-1.4h0a1,1,0,0,1,1.41.12h0a1.009,1.009,0,0,1-.12,1.41h0a1.053,1.053,0,0,1-.65.23h0A.985.985,0,0,1,665.31,362.24Zm4.86-3.5a.992.992,0,0,1,.43-1.35h0a.994.994,0,0,1,1.34.43h0a1,1,0,0,1-.43,1.35h0a.92.92,0,0,1-.46.11h0A.986.986,0,0,1,670.17,358.74Zm5.51-2.34a1,1,0,0,1,.71-1.22h0a1,1,0,0,1,1.22.71h0a1,1,0,0,1-.71,1.22h0a.783.783,0,0,1-.26.04h0A.991.991,0,0,1,675.68,356.4Zm5.89-1.07a1,1,0,0,1,.97-1.03h0a1,1,0,0,1,1.03.97h0a1,1,0,0,1-.97,1.03h-.03A1,1,0,0,1,681.57,355.33Z"
            transform="translate(631.57 -287.286)"
            fill="#ef4444"
          />
          <path
            id="Path_552"
            data-name="Path 552"
            d="M683.436,375.927l1.578,4.858h5.109l-4.133,3,1.566,4.858-4.133-3-4.133,3,1.58-4.858-4.133-3h5.109Z"
            transform="translate(631.57 -287.286)"
            fill="#ef4444"
          />
        </g>
      </svg>
      <span v-text="$t('logo')" />
    </router-link>
  </h1>
</template>

<script>
export default {
  name: "Logo",
};
</script>

<style></style>
