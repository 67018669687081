<template>
  <div
    class="fixed right-0 top-0 p-4 m-4w-full md:max-w-xs md:top-auto md:bottom-0"
  >
    <div class="bg-black p-3 md:p-6  bg-opacity-90 rounded  ">
      <p v-text="$t('misc.cookies_text')" class="text-xs text-white mb-4" />
      <button
        @click="handleAgreedToPrivacy"
        class="btn py-2 px-4 border border-white  text-white text-sm w-full"
        v-text="$t('button.agree_cookies')"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "CookiesBanner",
  methods: {
    handleAgreedToPrivacy() {
      this.$store.commit("updateAgreedToPrivacy", true);
    },
  },
};
</script>

<style></style>
