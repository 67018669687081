import i18n from "../i18n";

export const zodiacSigns = [
  {
    thumb: "/img/zodiac/aries.svg",
    name: i18n.t("signs.aries"),
    startDate: "3.21",
    endDate: "4.20",
    description: i18n.t("signs_descriptions.aries"),
  },
  {
    thumb: "/img/zodiac/taurus.svg",
    name: i18n.t("signs.taurus"),
    startDate: "4.21",
    endDate: "5.20",
    description: i18n.t("signs_descriptions.taurus"),
  },
  {
    thumb: "/img/zodiac/gemini.svg",
    name: i18n.t("signs.gemini"),
    startDate: "5.21",
    endDate: "6.21",
    description: i18n.t("signs_descriptions.gemini"),
  },
  {
    thumb: "/img/zodiac/cancer.svg",
    name: i18n.t("signs.cancer"),
    startDate: "6.22",
    endDate: "7.22",
    description: i18n.t("signs_descriptions.cancer"),
  },
  {
    thumb: "/img/zodiac/leo.svg",
    name: i18n.t("signs.leo"),
    startDate: "7.23",
    endDate: "8.22",
    description: i18n.t("signs_descriptions.leo"),
  },
  {
    thumb: "/img/zodiac/virgo.svg",
    name: i18n.t("signs.virgo"),
    startDate: "8.23",
    endDate: "9.22",
    description: i18n.t("signs_descriptions.virgo"),
  },
  {
    thumb: "/img/zodiac/libra.svg",
    name: i18n.t("signs.libra"),
    startDate: "9.23",
    endDate: "10.22",
    description: i18n.t("signs_descriptions.libra"),
  },
  {
    thumb: "/img/zodiac/scorpio.svg",
    name: i18n.t("signs.scorpio"),
    startDate: "10.23",
    endDate: "11.22",
    description: i18n.t("signs_descriptions.scorpio"),
  },
  {
    thumb: "/img/zodiac/sagittarius.svg",
    name: i18n.t("signs.sagittarius"),
    startDate: "11.23",
    endDate: "12.21",
    description: i18n.t("signs_descriptions.sagittarius"),
  },
  {
    thumb: "/img/zodiac/capricorn.svg",
    name: i18n.t("signs.capricorn"),
    startDate: "12.22",
    endDate: "1.20",
    description: i18n.t("signs_descriptions.capricorn"),
  },
  {
    thumb: "/img/zodiac/aquarius.svg",
    name: i18n.t("signs.aquarius"),
    startDate: "1.21",
    endDate: "2.18",
    description: i18n.t("signs_descriptions.aquarius"),
  },
  {
    thumb: "/img/zodiac/pisces.svg",
    name: i18n.t("signs.pisces"),
    startDate: "2.19",
    endDate: "3.20",
    description: i18n.t("signs_descriptions.pisces"),
  },
];
