<template>
  <div class="max-w-7xl w-full px-6 mx-auto relative">
    <slot />
  </div>
</template>

<script>
export default {
  name: "Container",
};
</script>
