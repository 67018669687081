export const DOMAINS = [
  {
    url: [
      "theme-astral-unique.com",
      "www.theme-astral-unique.com",
      "astro.fr.local",
    ],
    lang: "fr",
    googleAnalyticsId: "UA-115340529-20",
    googleAdsId: "AW-10845657261",
    googleConversionId: "AW-10845657261/arFPCNKJ1JUDEK2xzrMo",
  },
  {
    url: ["guida-astrale.com", "www.guida-astrale.com", "astro.it.local"],
    lang: "it",
    googleAnalyticsId: "UA-115340529-26",
    googleAdsId: "AW-10876286989",
    googleConversionId: "AW-10876286989/AuS6COW1x64DEI3wm8Io",
  },
];

export const currentDomain = () => {
  const host = window.location.hostname;
  const domain = DOMAINS.find((d) => d.url.includes(host));
  return domain;
};
