<template>
  <div class="relative z-20">
    <div @click="onClick">
      <slot name="dropdownTrigger" />
    </div>
    <transition name="fade">
      <div v-if="isOpen">
        <div
          class="fixed left-0 sm:left-auto top-0 sm:absolute sm:top-full sm:mt-2 text-gray-900 sm:w-80 right-0 rounded-b-xl sm:rounded bg-white p-6 shadow-xl z-50"
        >
          <slot name="dropdownContent" />
        </div>
        <div class="z-40 inset-0 fixed" @click="onClick" />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "Dropdown",
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    onClick() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>

<style></style>
