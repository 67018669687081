import i18n from "../i18n";

export const testimonials = [
  {
    name: i18n.t("testimonials.kristen.name"),
    age: i18n.t("testimonials.kristen.age"),
    location: i18n.t("testimonials.kristen.location"),
    text: i18n.t("testimonials.kristen.text"),
  },
  {
    name: i18n.t("testimonials.holly.name"),
    age: i18n.t("testimonials.holly.age"),
    location: i18n.t("testimonials.holly.location"),
    text: i18n.t("testimonials.holly.text"),
  },
];
