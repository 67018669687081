import Vue from "vue";
import VueRouter from "vue-router";
import { PREFIX, store } from "../store";
import i18n from "../i18n";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",

    component: () =>
      import(/* webpackChunkName: "guest" */ "../views/Guest.vue"),
    children: [
      {
        path: "/",
        name: "Homepage",
        meta: { currentStep: 1, isHomepage: true },
        component: () =>
          import(
            /* webpackChunkName: "step1" */ "../components/ZodiacFormComponents/SelectSign.vue"
          ),
      },
    ],
  },
  {
    path: "/reading",
    redirect: "/",
    component: () =>
      import(
        /* webpackChunkName: "zodiacForm" */ "../components/ZodiacForm.vue"
      ),
    children: [
      {
        path: "/step-1",
        name: "Step 1",
        meta: { currentStep: 1 },

        component: () =>
          import(
            /* webpackChunkName: "step1" */ "../components/ZodiacFormComponents/SelectSign.vue"
          ),
      },

      {
        path: "/step-2",
        name: "Step 2",
        meta: { currentStep: 2 },
        component: () =>
          import(
            /* webpackChunkName: "step2" */ "../components/ZodiacFormComponents/SelectDayAndMonth.vue"
          ),
      },
      {
        path: "/step-3",
        name: "Step 3",
        meta: { currentStep: 3 },
        component: () =>
          import(
            /* webpackChunkName: "step3" */ "../components/ZodiacFormComponents/SelectDecade.vue"
          ),
      },
      {
        path: "/step-4",
        name: "Step 4",
        meta: { currentStep: 4 },
        component: () =>
          import(
            /* webpackChunkName: "step4" */ "../components/ZodiacFormComponents/SelectYear.vue"
          ),
      },
      {
        path: "/step-5",
        name: "Step 5",
        meta: { currentStep: 5 },
        component: () =>
          import(
            /* webpackChunkName: "step5" */ "../components/ZodiacFormComponents/SelectTime.vue"
          ),
      },
      {
        path: "/step-6",
        name: "Step 6",
        meta: { currentStep: 6 },
        component: () =>
          import(
            /* webpackChunkName: "step6" */ "../components/ZodiacFormComponents/LastStep.vue"
          ),
      },
    ],
  },
  {
    path: `/${i18n.t("router.offer.path")}`,
    name: i18n.t("router.offer.name"),
    component: () =>
      import(/* webpackChunkName: "offer" */ "../views/Offer.vue"),
  },
  {
    path: `/${i18n.t("router.payment.path")}`,
    name: i18n.t("router.payment.name"),
    component: () =>
      import(/* webpackChunkName: "payment" */ "../views/Payment.vue"),
  },
  {
    path: `/${i18n.t("router.results.path")}`,
    name: i18n.t("router.results.name"),
    component: () =>
      import(/* webpackChunkName: "results" */ "../views/Results.vue"),
  },
  {
    path: `/${i18n.t("router.error.path")}`,
    name: i18n.t("router.error.name"),
    component: () =>
      import(/* webpackChunkName: "error" */ "../views/Error.vue"),
  },

  {
    path: "/page",
    name: "Page",
    component: () =>
      import(/* webpackChunkName: "step1" */ "../views/Static.vue"),
    children: [
      {
        path: `/page/${i18n.t("router.a_propos_des_signes_du_zodiac.path")}`,
        name: i18n.t("router.a_propos_des_signes_du_zodiac.name"),
        component: () =>
          import(
            /* webpackChunkName: "AboutZodiacSigns" */ "../views/static/AboutZodiacSigns.vue"
          ),
      },
      {
        path: `/page/${i18n.t("router.pourquoi_le_theme_astral.path")}`,
        name: i18n.t("router.pourquoi_le_theme_astral.name"),
        component: () =>
          import(
            /* webpackChunkName: "WhyTheAstralTheme" */ "../views/static/WhyTheAstralTheme.vue"
          ),
      },
      {
        path: `/page/${i18n.t("router.analyses_astro_de_personnalites.path")}`,
        name: i18n.t("router.analyses_astro_de_personnalites.name"),
        component: () =>
          import(
            /* webpackChunkName: "WhyAreWeUnique" */ "../views/static/WhyAreWeUnique.vue"
          ),
      },
      {
        path: `/${i18n.t("router.reports.path")}/:id`,
        name: i18n.t("router.reports.name"),
        component: () =>
          import(
            /* webpackChunkName: "PersonalityReports" */ "../views/PersonalityReports.vue"
          ),
      },
    ],
  },

  {
    path: "*",
    component: () =>
      import(/* webpackChunkName: "404" */ "../views/NotFound.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL || "/",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes,
});

// protect routes
router.beforeEach((to, from, next) => {
  const completedStep =
    JSON.parse(localStorage.getItem(PREFIX + "completedStep")) ||
    store.state.completedStep;
  const currentStep = to.meta.currentStep;
  const totalSteps = store.state.totalSteps;
  // const readingComplete =
  //   JSON.parse(localStorage.getItem(PREFIX + "readingComplete")) ||
  //   store.state.readingComplete;

  // const offerSelected =
  //   JSON.parse(localStorage.getItem(PREFIX + "offerSelected")) ||
  //   store.state.offerSelected;

  if (!from.name) {
    if (to.name === i18n.t("router.error.name")) {
      next({
        name: i18n.t("router.payment.name"),
      });
    } else if (
      to.name === i18n.t("router.payment.name") &&
      completedStep < totalSteps
    ) {
      next({
        name: `Step ${completedStep + 1}`,
      });
    } else if (
      to.name === i18n.t("router.results.name") &&
      completedStep < totalSteps
    ) {
      next({
        name: `Step ${completedStep + 1}`,
      });
    } else if (currentStep && currentStep > completedStep + 1) {
      next({
        name: `Step ${completedStep + 1}`,
      });
    } else if (completedStep > 1 && currentStep < completedStep + 1) {
      next({
        name:
          completedStep < totalSteps
            ? `Step ${completedStep + 1}`
            : i18n.t("router.offer.name"),
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
