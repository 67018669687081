<template>
  <container>
    <div
      class="flex flex-col md:flex-row items-center justify-between text-xs py-6 opacity-50"
    >
      <p
        v-text="$t('misc.footer_copyright')"
        class="order-last md:order-first"
      />
      <ul
        class="flex flex-col md:flex-row -mx-3 order-first md:order-last mb-4 text-center md:mb-0"
      >
        <li class="px-3" v-if="locale === 'fr'">
          <a
            :href="`/img/CGU-${locale}.png`"
            target="_blank"
            rel="noopener noreferrer"
            class="hover:opacity-75"
            v-text="$t('button.cgu')"
          />
        </li>
        <li class="px-3">
          <a
            :href="`/img/CGV-${locale}.png`"
            target="_blank"
            rel="noopener noreferrer"
            class="hover:opacity-75"
            v-text="$t('button.cgv')"
          />
        </li>
        <li class="px-3">
          <a
            :href="`/img/Politique_des_cookies-${locale}.png`"
            target="_blank"
            rel="noopener noreferrer"
            class="hover:opacity-75"
            v-text="$t('button.politique_des_cookies')"
          />
        </li>
        <li class="px-3">
          <a
            :href="`/img/Politique_de_confidentialite-${locale}.png`"
            target="_blank"
            rel="noopener noreferrer"
            class="hover:opacity-75"
            v-text="$t('button.privacy_policy')"
          />
        </li>
        <li class="px-3">
          <a
            :href="`/img/Mentions_legales-${locale}.png`"
            target="_blank"
            rel="noopener noreferrer"
            class="hover:opacity-75"
            v-text="$t('button.mentions_legales')"
          />
        </li>
      </ul>
    </div>
  </container>
</template>

<script>
import Container from "./Ui/Container.vue";

export default {
  components: { Container },
  name: "Footer",
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
};
</script>
