<template>
  <button
    @click="onClick"
    class="flex-shrink-0 p-2 transition-colors focus:text-red-500 lg:hover:text-red-500"
  >
    <inline-svg src="/img/icon-back.svg" class="fill-current" />
  </button>
</template>

<script>
export default {
  name: "BackButton",
  props: {
    completedStep: {
      type: Number,
      required: true,
    },
  },

  methods: {
    onClick() {
      this.$router.push(
        this.$route.meta.currentStep === 2
          ? "/"
          : `/step-${this.$route.meta.currentStep - 1}`
      );
    },
  },
};
</script>

<style></style>
